import { yup } from "@utils/validation";
import { CONTENT_MAX_CHARS, CONTENT_SMALL_MAX_CHARS, TITLE_MAX_CHARS } from "./constants";
import { t } from "@utils/i18n";

/***** UNITS schemas *****/

const nameSchema = yup.string().trim().required().max(TITLE_MAX_CHARS);
const contentSchema = yup.string().trim().nullable().min(1).max(CONTENT_MAX_CHARS);
const contentSmallSchema = yup.string().trim().nullable().min(1).max(CONTENT_SMALL_MAX_CHARS);
const fileIdSchema = yup.number().nullable().min(1);
const urlSchema = yup.string().trim().nullable();

export const RenameUnitValidationSchema = yup.object().shape({
  name: nameSchema,
});

export const ContentUnitValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSchema,
});

export const DocumentUnitValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSchema,
  secondary_content: contentSchema,
  file_id: fileIdSchema,
  url: urlSchema,
});

export const VideoUnitValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSchema,
  secondary_content: contentSchema,
  file_id: fileIdSchema,
  url: urlSchema,
});

export const AudioUnitValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSchema,
  secondary_content: contentSchema,
  file_id: fileIdSchema,
  url: urlSchema,
});

export const IFramValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSchema,
  secondary_content: contentSchema,
  url: urlSchema,
});

export const WebContentValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSchema,
  secondary_content: contentSchema,
  url: urlSchema,
});

export const TestValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSmallSchema,
});

export const SurveyOptionsValidationSchema = yup.object().shape({
  survey_options: yup.object().shape({
    show_answers: yup.boolean(),
    mandatory_answers: yup.boolean(),
  }),
});

export const IltUnitValidationSchema = yup.object().shape({
  name: nameSchema,
  content: contentSmallSchema,
});

/***** UNIT OPTIONS schemas *****/

export const UnitOptionsValidationSchema = yup.object().shape({
  completion_method: yup.object().shape({
    type: yup.string().required(),
    time: yup.number().integer().min(1).max(172800),
    question_id: yup
      .number()
      .min(1)
      .nullable() // used to clear select, undefined keeps the prev value selected
      .when("type", {
        is: (value: string) => value === "question",
        then: (schema) => schema.required(),
      }),
  }),
  media_options: yup.object().shape({
    auto_play: yup.boolean(),
    auto_pause: yup.boolean(),
    sync_timer: yup.boolean(),
    show_speed: yup.boolean(),
  }),
  captions_file_id: yup.number().nullable(),
  poster_image_id: yup.number().nullable(),
});

export const AssignmentUnitOptionsValidationSchema = UnitOptionsValidationSchema.shape({
  maxtimeallowed: yup.number().integer().min(1).max(8388607).nullable(),
  assignment_options: yup.object().shape({
    due_datetime: yup.string().nullable(),
    reply_options: yup
      .object()
      .shape({
        text: yup.boolean(),
        file: yup.boolean(),
        video_recording: yup.boolean(),
        audio_recording: yup.boolean(),
        screen_recording: yup.boolean(),
      })
      .test(
        "reply_options_at_least_one_enabled",
        () => t("unitEdit.validationMessages.atLeastOneReplyOption"),
        (options) => {
          return Object.values(options).some((value) => !!value);
        },
      ),
  }),
});

export const ScormUnitOptionsValidationSchema = yup.object().shape({
  size: yup.object().shape({
    width: yup.number().nullable().min(200).max(10000).integer(),
    height: yup.number().nullable().min(200).max(10000).integer(),
  }),
  embed_type: yup.string(),
  retain_failed_status: yup.boolean(),
});

export const TestUnitOptionsValidationSchema = yup.object().shape({
  maxtimeallowed: yup.number().min(1).max(8388607).nullable(),
  test_options: yup
    .object()
    .shape({
      completion_threshold: yup.number().min(0).max(100).required(),
      shuffle_questions: yup.boolean(),
      shuffle_answers: yup.boolean(),
      repeatable_when: yup.string().required(),
      attempts_limit_times: yup.number().min(1).nullable(),
      "delay_attempt_1-2": yup.number().min(1).nullable(),
      "delay_attempt_2-3": yup.number().min(1).nullable(),
      "delay_attempt_3-4": yup.number().min(1).nullable(),
      "delay_attempt_4-5": yup.number().min(1).nullable(),
      show_correct_answers_when: yup.string().required(),
      show_given_answers: yup.boolean(),
      show_indicator: yup.boolean(),
      show_score: yup.boolean(),
      show_statistics: yup.boolean(),
      hide_correctly_answered_questions: yup.boolean(),
      show_feedback_when: yup.string().required(),
      go_back_forth: yup.boolean(),
      check_answers: yup.boolean(),
      abandon_immediately: yup.boolean(),
      test_security_type: yup.string().nullable(),
      test_password: yup.string().trim().max(30),
      message_if_passed: yup.string().trim().max(800).nullable(),
      message_if_notpassed: yup.string().trim().max(800).nullable(),
    })
    .test("test_password_required", t("validationMessages.requiredField"), (options) => {
      const { test_password, hasTestPassword, test_security_type } = options;

      // not required if test_security_type is not password
      if (test_security_type !== "password") return true;
      // not required if there is a password previously created
      if (hasTestPassword) return true;

      return yup.string().trim().required().isValid(test_password);
    }),
});

const weightSchema = yup.object({
  id: yup.number().min(1).required(),
  weight: yup.number().min(0).max(20).required(),
});

export const TestUnitWeightValidationSchema = yup.object().shape({
  questions: yup
    .array()
    .of(weightSchema)
    .test("overall_weight_zero", t("unitEdit.validationMessages.zeroTotalWeight"), (questions) => {
      const sum =
        questions?.reduce((acc, { weight }) => {
          return weight ? acc + weight : acc;
        }, 0) || 0;

      return sum > 0;
    }),
});
