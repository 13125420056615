import React, { FC } from "react";
import { Button, ProgressBar, Text } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { courseCardProgressBar } from "../../components/styles";
import { getButtonColor } from "../../helpers";
import { Course } from "types/entities";
import { formatUtcDate, getButtonAvailabilityText } from "@utils/helpers";
import { useConfigurationStore } from "@stores";
import Price from "@components/Price/Price";
import { useApplyTranslations } from "@hooks";
import userRoles from "@constants/userRoles";

export type EnrolledActionsProps = {
  course: Course;
  children?: never;
};

const MAIN_BUTTON_TEXT = {
  completed: "general.progress.completed",
  failed: "general.progress.notPassed",
};

const EnrolledActions: FC<EnrolledActionsProps> = ({ course }) => {
  const { t } = useApplyTranslations();
  const { catalogSettings } = useConfigurationStore();
  const { availability, role_in_course, progress, price, user_access_expires_at } = course;
  const isCourseAvailable = Boolean(availability?.is_available);
  const hasExpirationRestriction = !!user_access_expires_at;
  const isLearner = role_in_course === userRoles.LEARNER;
  const isInstructor = role_in_course === userRoles.INSTRUCTOR;
  const isCourseCompleted =
    progress?.completion_status === "completed" || progress?.completion_status === "failed";
  const isSubscriptionEnabled = Boolean(catalogSettings?.subscription.enabled);
  const hasLearnerExpirationRestriction = hasExpirationRestriction && isLearner;
  const isCourseCancelled = !availability?.is_available && availability?.reason === "cancelled";
  const buttonColor = isCourseCancelled
    ? "primary"
    : hasLearnerExpirationRestriction
    ? "danger"
    : "secondary";

  // If subscriptions are enabled
  if (isSubscriptionEnabled && availability?.reason === "cancelled" && price) {
    return (
      <Button block className="footer-btn">
        <Text fontSize="sm" as="div" className="subscribe-btn-text">
          {t("general.subscribe")} (
          <Price value={catalogSettings?.subscription.price?.amount ?? 0} />)
        </Text>
      </Button>
    );
  }

  // TODO: Check if the following code can be simplified
  return (
    <>
      {
        //course is completed or failed
        isCourseCompleted ? (
          //check if course is available
          <Button
            block
            color={isCourseAvailable ? getButtonColor(progress.completion_status) : "secondary"}
            className="footer-btn"
          >
            {isCourseAvailable
              ? t(MAIN_BUTTON_TEXT[progress.completion_status])
              : getButtonAvailabilityText(course)}
          </Button>
        ) : (
          //if course is not attempted or incomplete then check if we have any restrictions to show, else show progress
          <>
            {!isCourseAvailable || hasLearnerExpirationRestriction || isInstructor ? (
              <Button block className="footer-btn" color={buttonColor}>
                {hasLearnerExpirationRestriction ? (
                  <>
                    {t("restrictions.expires", {
                      expiresAt: formatUtcDate(user_access_expires_at),
                    })}
                  </>
                ) : (
                  <>
                    {!isInstructor
                      ? isCourseCancelled
                        ? t("courseCatalog.getCourse")
                        : getButtonAvailabilityText(course)
                      : t("courseCatalog.viewCourse")}
                  </>
                )}
              </Button>
            ) : (
              <ProgressBar
                percent={progress?.completion_percentage ?? 0}
                rounded={false}
                css={(theme): SerializedStyles =>
                  courseCardProgressBar(theme, {
                    progress: progress?.completion_percentage ?? 0,
                  })
                }
              />
            )}
          </>
        )
      }
    </>
  );
};

export default EnrolledActions;
