import React, { FC, MouseEvent } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";

import { ActionGhostButton } from "@components/ReusableComponents";

export type ActionButtonProps = {
  tooltipContent: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

const ActionButton: FC<ActionButtonProps> = ({
  tooltipContent,
  disabled = false,
  onClick,
  children,
  ...rest
}) => {
  return (
    <Tooltip content={tooltipContent} parentProps={{ "aria-label": tooltipContent }}>
      <ActionGhostButton disabled={disabled} onClick={onClick} {...rest}>
        {children}
      </ActionGhostButton>
    </Tooltip>
  );
};

export default ActionButton;
