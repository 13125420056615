import { config, getBaseUrl } from "@config";
import { URLS } from "@constants/urls";
import { MandatoryCustomFieldSchema, MaxLengthCustomFieldSchema, yup } from "@utils/validation";
import { t } from "@utils/i18n";
import { DraggableLocation } from "react-beautiful-dnd";
import { SelectOption } from "types/common";
import { CustomField, FormCustomFields, MyUnit, Section, UnitType } from "types/entities";
import { AnyObjectSchema, StringSchema } from "yup";
import { EditCourseData } from "./api";

type stringFieldSchema = {
  [key: string]: StringSchema;
};

// reorder a single unit
export const reorderSingleUnit = (
  list: Array<Section | MyUnit>,
  startIndex: number,
  endIndex: number,
): Array<Section | MyUnit> => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export type ReorderMultipleUnitsProps = {
  list: Array<Section | MyUnit>;
  itemsToMove: number[];
  source: DraggableLocation;
  destination: DraggableLocation;
};

export const reorderMultipleUnits = ({
  list,
  itemsToMove,
  source,
  destination,
}: ReorderMultipleUnitsProps): Array<Section | MyUnit> => {
  // split list into 2 new arrays, one with the removed items and one with the rest
  const [reorderUnits, removed] = list.reduce(
    ([units, removed], currentUnit) =>
      !itemsToMove.includes(currentUnit.id)
        ? [[...units, currentUnit], removed]
        : [units, [...removed, currentUnit]],
    [[] as Array<Section | MyUnit>, [] as Array<Section | MyUnit>],
  );

  // destination position is before the source position, move to destination
  if (destination.index <= source.index) {
    reorderUnits.splice(destination.index, 0, ...removed);

    // destination position is after the source position
    // have to find the new index to move the selected elements
  } else {
    const destinationItem = list[destination.index];
    const newDestinationIndex = reorderUnits.findIndex((unit) => unit.id === destinationItem.id);

    // apply to the index right after the found one
    reorderUnits.splice(newDestinationIndex + 1, 0, ...removed);
  }

  return reorderUnits;
};

export const isSectionItem = (data: Section | MyUnit): data is Section => {
  return (data as Section).units !== undefined;
};

export const parentHasClassname = (targetElement: Element, className: string): boolean => {
  const nodes = [];
  let element = targetElement;

  while (element.parentElement) {
    if (element.parentElement.classList.contains(className)) {
      nodes.unshift(element.parentElement);
    }
    element = element.parentElement;
  }

  return nodes.length > 0;
};

export const mapUnitsToSelectOption = (units: MyUnit[]): SelectOption[] =>
  units.map((unit) => ({ label: unit.name, value: unit.id.toString() } as SelectOption));

export const getCustomFieldSchema = (customFields: CustomField[]): AnyObjectSchema | undefined => {
  if (!customFields) return undefined;
  // get all mandatory custom fields
  const mandatoryFields = customFields.filter((item) => item.mandatory).map((a) => a.id);

  // create object with mandatory custom fields validations
  const mandatoryFieldsSchema: stringFieldSchema = mandatoryFields.reduce((schema, field) => {
    schema[field] = MandatoryCustomFieldSchema;
    return schema;
  }, {});

  // get all fields with max length
  const maxLengthFields = customFields.filter((item) => item.max_length);

  // create object with max length custom fields validations
  const maxLengthFieldsSchema: stringFieldSchema = maxLengthFields.reduce((schema, field) => {
    if (field.max_length) {
      schema[field.id] = MaxLengthCustomFieldSchema(field.max_length);
    }
    return schema;
  }, {});

  if (mandatoryFields.length || maxLengthFields.length) {
    // create validation schema for custom fields
    const customFieldValidationSchema = yup.object().shape({
      custom_fields: yup
        .object()
        .shape({ ...mandatoryFieldsSchema })
        .concat(yup.object().shape({ ...maxLengthFieldsSchema })),
    });

    // return custom validation schema
    return customFieldValidationSchema;
  }

  return undefined;
};

export const getOptionsTitle = (type: UnitType | null): string => {
  switch (type) {
    case "test":
    case "assignment":
    case "survey":
      return t(`unitEdit.options.title.${type}`);
    default:
      return t("unitEdit.options.title.unit");
  }
};

export const getCoursePublicUrl = (publicKey: string): string => {
  const originUrl = window.location.origin;
  const baseUrl = config.NODE_ENV != "dev" ? getBaseUrl() : "";

  return `${originUrl}${baseUrl}${URLS.publicCourse.getNavigatorUrl({ publicKey })}`;
};

export const mapFormDataToSubmitData = (
  data: EditCourseData,
  customFields?: CustomField[],
): EditCourseData => {
  if (!data.custom_fields) return data;
  const newCustomFields = customFields?.reduce<FormCustomFields>(
    (acc: FormCustomFields, { id, name }: CustomField) => {
      const value = data?.custom_fields?.[id];
      if (value) acc[name] = value;
      return acc;
    },
    {},
  );
  return {
    ...data,
    custom_fields: newCustomFields,
  };
};
